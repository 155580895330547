import React, { useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { Formik, Form } from "formik";
import MultiPageForm from "components/ui/multi-page-form";
import ChooseSupplies from "./choose-supplies";
import Usage from "./usage";
import AdditionalInfo from "./additional-info";
import OrderSummary from "./order-summary";
import ShippingDetails from "./shipping-details";
import getInitialValues from "./initial-values";
import { createPatientOrderNew } from "actions/order-form";
import {
  validateChooseSuppliesForm,
  validateAdditionalInfoForm,
  validateShippingDetails
} from "./validation";
import { getPhilipsWorkflowRequired } from "components/profile/philips-recall/helpers";
import { PatientProfileContext } from "components/profile/context";
import { supplyOptions } from "./options";

const OrderForm = ({
  patientInfo,
  userRole,
  handleCreateOrderSuccess,
  createPatientOrderNew,
  orderFormEnabled,
  handleOrderFormX
  //eligibleEquipmentType,
  //patientHCPCHistory
}) => {
  const { address, physician, uses_bonafide_api, id, insurances } = patientInfo;

  const {
    fetchPatientHCPCHistoryAPI,
    companyInsurances,
    company_order_settings
  } = useContext(PatientProfileContext);

  const patientHCPCHistory =
    fetchPatientHCPCHistoryAPI?.response?.eligiblity ?? [];

  const eligibleEquipmentType = patientHCPCHistory
    .filter(x => x.isEligible && x.productType)
    .map(x => x.productType.toLowerCase());

  return orderFormEnabled === true ? (
    <div className="orderFormContainer">
      {handleOrderFormX && (
        <div onClick={handleOrderFormX} className="orderFormX">
          &#x2715;
        </div>
      )}
      <h4>Order Form</h4>

      <Formik
        initialValues={{
          ...getInitialValues(patientInfo),
          requested_equipment: supplyOptions
            .filter(
              equip =>
                company_order_settings.equipment_type_options?.includes(
                  equip.value
                ) && eligibleEquipmentType?.includes(equip.value)
            )
            .map(x => x.value),
          supply_period: String(
            Array.isArray(company_order_settings?.supply_period_options)
              ? company_order_settings?.supply_period_options[
                  company_order_settings?.supply_period_options?.length - 1
                ] ?? 90
              : 90
          ),
          patient_id: id
        }}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const philipsWorkflowRequired =
              getPhilipsWorkflowRequired(patientInfo);
            if (philipsWorkflowRequired) window.alert(philipsWorkflowRequired);
            else {
              const orderId = await createPatientOrderNew(values);
              if (orderId && handleCreateOrderSuccess) {
                handleCreateOrderSuccess(orderId);
              }
            }
          } catch (error) {
            console.log(error);
            setSubmitting(false);
          }
        }}
        initialTouched={{
          saw_physician_last: true,
          changed_insurance: true,
          caregiver_name: true,
          reason_for_no_mask: true
        }}
      >
        {formProps => (
          <Form className="order-form">
            <MultiPageForm
              onSubmit={formProps.handleSubmit}
              isSubmitting={formProps.isSubmitting}
              pages={[
                {
                  title: "Choose Supplies",
                  view: (
                    <ChooseSupplies
                      {...formProps}
                      patientHCPCHistory={patientHCPCHistory}
                      eligibleEquipmentType={eligibleEquipmentType}
                      patientInfo={patientInfo}
                    />
                  ),
                  validation: validateChooseSuppliesForm(formProps, userRole)
                },
                company_order_settings.usage === true && {
                  title: "Usage",
                  view: <Usage {...formProps} />
                },
                {
                  title: "Additional Information",
                  view: (
                    <AdditionalInfo
                      {...formProps}
                      physician={physician}
                      usesBonafideApi={uses_bonafide_api}
                      insuranceOptions={companyInsurances}
                      patientInsurances={insurances}
                    />
                  ),
                  validation: validateAdditionalInfoForm(formProps)
                },
                // uses_bonafide_api && {
                //   title: "Patient Verification",
                //   view: <BonafidePatientVerification {...formProps} />,
                //   validation: true
                // },
                {
                  title: "Order Summary",
                  view: (
                    <OrderSummary {...formProps} patientInfo={patientInfo} />
                  ),
                  validation: true
                },
                {
                  title: "Shipping Details",
                  view: <ShippingDetails {...formProps} address={address} />,
                  validation: validateShippingDetails(formProps)
                }
              ].filter(Boolean)}
            />
          </Form>
        )}
      </Formik>
    </div>
  ) : null;
};

export default connect(
  (state, { patientId }) => ({
    patientInfo: selectors.getPatientInfo(state, patientId),
    userRole: selectors.getUserRole(state),
    insuranceOptions: selectors.getFormOptionsInsurance(state)
    // eligibleEquipmentType: selectors.getPatientEligibleEquipment(
    //   state,
    //   patientId
    // ),
    //patientHCPCHistory: selectors.getPatientsHcpcsHistory(state, patientId)
  }),
  { createPatientOrderNew }
)(OrderForm);

OrderForm.propTypes = {
  userRole: PropTypes.string,
  patientId: PropTypes.string.isRequired,
  patientInfo: PropTypes.object.isRequired,
  handleCreateOrderSuccess: PropTypes.func,
  createPatientOrderNew: PropTypes.func.isRequired,
  orderFormEnabled: PropTypes.bool,
  handleOrderFormX: PropTypes.func,
  insuranceOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  eligibleEquipmentType: PropTypes.arrayOf(PropTypes.string)
  //patientHCPCHistory: PropTypes.arrayOf(PropTypes.object).isRequired
};
