import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import InventoryTableRow from "./fulfillment-inventory-table-row";

const filterInventoryItem = (searchValue, searchBy) => inventoryItem => {
  if (searchValue.length > 0) {
    switch (searchBy) {
      case "product_name":
        return inventoryItem.item_name
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      case "crosswalk_id":
        return inventoryItem.crosswalk_id
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      case "product_id":
        return inventoryItem.product_id
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      case "hcpcs":
        return inventoryItem.hcpcs
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      default:
        return false;
    }
  }
  return true;
};

const InventoryTable = ({
  equipmentTypeOptions,
  manufacturerOptions,
  inventoryItems,
  searchValue,
  searchBy,
  containerClass,
  // rowComponent,
  onRowChange
}) => {
  const { rows, query, queryDispatcher } = useLocalQuery(inventoryItems, {
    sort: { by: "name", direction: "asc" },
    filters: {}
  });
  const [results, setResults] = useState(rows);
  const getFilteredInventoryRows = async () => {
    setResults(rows.filter(filterInventoryItem(searchValue, searchBy)));
  };
  useEffect(() => {
    getFilteredInventoryRows();
  }, [rows]);
  useEffect(() => {
    getFilteredInventoryRows();
  }, [searchValue]);

  return (
    <Table
      TableRow={InventoryTableRow}//{rowComponent || InventoryTableRow}
      onRowChange={onRowChange}
      containerClass={"query-table inventory-table " + containerClass}
      columns={[
        {
          header: "Name",
          field: "item_name",
          style: { width: "500px" }
        },
        {
          header: "Product Id",
          field: "product_id"
        },
        {
          header: "Crosswalk ID",
          field: "crosswalk",
          filterKey: "crosswalk_id",
        },
        {
          header: "UOM",
          field: "uom",
        },
        {
          header: "Active",
          field: "active",
        },
        {
          header: "HCPCS",
          field: "hcpcs"
        },
        {
          header: "Equipment Type",
          field: "product_type",
          filterKey: "product_type",
          filters: equipmentTypeOptions
        },
        {
          header: "Manufacturer",
          field: "manufacturer",
          filterKey: "manufacturer_id",
          filters: manufacturerOptions
        }
      ]}
      query={query}
      queryDispatcher={queryDispatcher}
      rows={results}
      rowKey="id"
    />
  );
};

export default connect(state => ({
  manufacturerOptions: selectors.getManufacturerOptions(state),
  equipmentTypeOptions: selectors.getEquipmentTypeOptions(state)
}))(InventoryTable);

InventoryTable.propTypes = {
  equipmentTypeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  manufacturerOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  inventoryItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchValue: PropTypes.string,
  searchBy: PropTypes.string,
  containerClass: PropTypes.string,
  // rowComponent: PropTypes.elementType,
  onRowChange: PropTypes.func
};

InventoryTable.defaultProps = {
  containerClass: ""
}
