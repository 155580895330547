import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { formatDate } from "utils/misc";
import { isBeforeNow } from "utils/dates";
import { RowSelectCell } from "hooks/useRowSelectNew";
import { PatientsTableSettingsContext } from "./settings";
import IoMdCheckmark from "react-icons/lib/io/checkmark";

const PatientsTableRow = ({
  id,
  active,
  first_name,
  last_name,
  account_number,
  form_of_contact,
  sleep_coach,
  compliance_coach_first_name,
  compliance_coach_last_name,
  compliance_met,
  company,
  insurance_company,
  doctorName,
  patient_insurance_eligible,
  dateEligibleForAutomation,
  cycle_count,
  delayed_activation_date,
  on_hold,
  new_setup,
  remote_setup,
  has_order_after_added,
  date_added,
  last_updated,
  has_recalled_machine,
  has_acknowledged_recall,
  recall_action_status,
  repap_eligible,
  wants_to_repap,
  language,
  active_form,
  patient_stage,
  compliance_inactivation_reason
}) => {
  const { isColumnVisible } = useContext(PatientsTableSettingsContext);
  function checkColumn(field) {
    return isColumnVisible({ field });
  }
  return (
    <tr>
      {checkColumn("all") && <RowSelectCell id={id} />}
      {checkColumn("active") && <td>{active && <IoMdCheckmark />}</td>}
      {checkColumn("compliance_met") && <td>{compliance_met}</td>}
      {checkColumn("last_name") && (
        <td>
          <Link to={`/patients/${id}`}>
            {first_name} {last_name}
          </Link>
        </td>
      )}
      {checkColumn("account_number") && <td>{account_number}</td>}
      {checkColumn("form_of_contact") && <td>{form_of_contact}</td>}
      {checkColumn("insurance_company") && <td>{insurance_company}</td>}
      {checkColumn("doctorName") && <td>{doctorName}</td>}
      {checkColumn("sleep_coach") && <td>{sleep_coach}</td>}
      {checkColumn("compliance_coach") && (
        <td>
          {(compliance_coach_first_name ?? "") +
            " " +
            (compliance_coach_last_name ?? "")}
        </td>
      )}
      {checkColumn("company") && <td>{company}</td>}
      {checkColumn("patient_insurance_eligible") && (
        <td>{formatDate(patient_insurance_eligible)}</td>
      )}
      {checkColumn("dateEligibleForAutomation") && (
        <td
          style={{
            color: isBeforeNow(dateEligibleForAutomation) ? "green" : "default"
          }}
        >
          {formatDate(dateEligibleForAutomation)}
        </td>
      )}
      {checkColumn("cycle_count") && <td>{cycle_count}</td>}
      {checkColumn("delayed_activation_date") && (
        <td>{formatDate(delayed_activation_date)}</td>
      )}
      {checkColumn("date_added") && <td>{formatDate(date_added)}</td>}
      {checkColumn("last_updated") && <td>{formatDate(last_updated)}</td>}
      {checkColumn("language") && <td>{language}</td>}
      {checkColumn("has_order_after_added") && (
        <td>{has_order_after_added && <IoMdCheckmark />}</td>
      )}
      {checkColumn("new_setup") && <td>{new_setup && <IoMdCheckmark />}</td>}
      {checkColumn("new_setup") && <td>{remote_setup && <IoMdCheckmark />}</td>}
      {checkColumn("on_hold") && <td>{on_hold && <IoMdCheckmark />}</td>}
      {checkColumn("active_form") && <td>{active_form && <IoMdCheckmark />}</td>}
      {checkColumn("patient_stage") && <td><span>{patient_stage?.Description ?? ""}</span></td>}
      {checkColumn("has_recalled_machine") && (
        <td>{has_recalled_machine && <IoMdCheckmark />}</td>
      )}
      {checkColumn("has_acknowledged_recall") && (
        <td>{has_acknowledged_recall && <IoMdCheckmark />}</td>
      )}
      {checkColumn("recall_action_status") && <td>{recall_action_status}</td>}
      {checkColumn("repap_eligible") && (
        <td>{repap_eligible && <IoMdCheckmark />}</td>
      )}
      {checkColumn("wants_to_repap") && (
        <td>{wants_to_repap && <IoMdCheckmark />}</td>
      )}
      {checkColumn("compliance_inactivation_reason") && (
        <td>{compliance_inactivation_reason}</td>
      )}      
    </tr>
  );
};

export default PatientsTableRow;

PatientsTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  active: PropTypes.bool,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  account_number: PropTypes.string,
  form_of_contact: PropTypes.string,
  sleep_coach: PropTypes.string,
  compliance_coach_first_name: PropTypes.string,
  compliance_coach_last_name: PropTypes.string,
  compliance_met: PropTypes.string,
  company: PropTypes.string,
  insurance_company: PropTypes.string,
  doctorName: PropTypes.string,
  patient_insurance_eligible: PropTypes.string,
  dateEligibleForAutomation: PropTypes.string,
  cycle_count: PropTypes.number,
  delayed_activation_date: PropTypes.string,
  on_hold: PropTypes.bool,
  new_setup: PropTypes.bool,
  remote_setup: PropTypes.bool,
  has_order_after_added: PropTypes.bool,
  date_added: PropTypes.string,
  has_recalled_machine: PropTypes.bool,
  has_acknowledged_recall: PropTypes.bool,
  has_completed_recall: PropTypes.bool,
  repap_eligible: PropTypes.bool,
  wants_to_repap: PropTypes.bool,
  recall_action_status: PropTypes.string,
  language: PropTypes.string,
  last_updated: PropTypes.string,
  active_form: PropTypes.bool,
  patient_stage: PropTypes.string,
  compliance_inactivation_reason: PropTypes.string
};
